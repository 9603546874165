import React from 'react'

import { Input, Typography, Button } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import useTemplate from '@hooks/useTemplate'
import RequestVerificationCode from '@components/encapsulators/RequestVerificationCode'
import Logout from '@HOCs/Logout'

const translate = {
  es: {
    TITLE: 'Código de verificación',
    DESCRIPTION: 'Enviamos en código de 6 dígitos a tu correo.',
    CHECK: 'Verificar',
    LOGOUT: 'Cerrar sesión'
  }
}

function CodeTemplate () {
  const { onSubmit, loading } = useTemplate()

  return (
    <Wrapper>
      <Content>
        <div>
          <Typography type='title-4' weight={700}>{translate['es'].TITLE}</Typography>
          <Typography type='description'>{translate['es'].DESCRIPTION}</Typography>
        </div>

        <Input
          name='code'
          size='large'
          minLength={0}
          maxLength={6}
          placeholder='••••••'
        />

        <Button onClick={onSubmit} size='medium' loading={loading}>{translate['es'].CHECK}</Button>

        <RequestVerificationCode />
        
        <Logout>
          <Button type='link' size='small'>{translate['es'].LOGOUT}</Button>
        </Logout>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.NEUTRAL.BACKGROUND};
`

const Content = styled.div`
  width: 450px;
  display: grid;
  flex-direction: column;
  gap: 15px;
  margin: 0 12px;
`

export default CodeTemplate
