import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import useAuth from '@hooks/useAuth'

const propTypes = {
  children: PropTypes.element.isRequired,
}

type Props = InferProps<typeof propTypes>

function Logout({ children }: Props) {
  const { signOut } = useAuth()

  return React.cloneElement(children, { onClick: signOut })
}

Logout.propTypes = propTypes

export default Logout
