import React, { useMemo } from 'react'
import useVerificationCode from '@hooks/useVerificationCode'
import TextWithCallToAction from '../molecules/TextWithCallToAction'

function RequestVerificationCode () {
  const { iCanRequestCode, requestCode, secondsRemaining, lastDateLoaded, loading } = useVerificationCode()

  const actionTextWithSeconds = useMemo(() => {
    const seconds = !iCanRequestCode ? ` (${secondsRemaining})` : ''
    return `Reenviar${seconds}`
  }, [iCanRequestCode, secondsRemaining])

  const isLoading = useMemo(() => !lastDateLoaded || loading, [lastDateLoaded, loading])

  return (
    <TextWithCallToAction
      text='¿No has recibido el código?'
      toActionText={actionTextWithSeconds}
      toAction={requestCode}
      disabled={!iCanRequestCode}
      loading={isLoading}
    />
  )
}

export default RequestVerificationCode
