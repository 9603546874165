import { useState, useEffect, useCallback, useRef } from 'react'

import { useLazyFetch } from '@wicadu/arepa/hooks'
import moment, { Moment, Duration } from 'moment'

import { setItem, getItem } from '../utils/localStorage'
import useAuth from './useAuth'
import isBrowser from '@utils/isBrowser'

const secondsToWait: number = 45

export default function useVerificationCode () {
  const [ lastDateLoaded, setLastDateLoaded ] = useState(false)
  const [ iCanRequestCode, setICanRequestCode ] = useState(false)
  const [ secondsRemaining, setSecondsRemaining ] = useState(0)
  const intervalRef = useRef<any>(null)

  const { accessToken } = useAuth()

  const getCurrentDateAsMoment = useCallback((): Moment => moment.utc(), [])

  const checkSecondsRemainingAndClearInterval = useCallback((secondsRemaining: number): void => {
    if (secondsRemaining > 0) return

    setICanRequestCode(true)
    clearInterval(intervalRef.current)
  }, [setICanRequestCode, intervalRef])

  const calculateSecondsRemaining = useCallback((lastCodeRequestDate: string): void => {
    const currentDateAsMoment: Moment = getCurrentDateAsMoment()
    const lastDateToMoment = moment.utc(lastCodeRequestDate)
    const diff: Duration = moment.duration(currentDateAsMoment.diff(lastDateToMoment))
    const diffSeconds: number = Math.round(diff.asSeconds())
    const secondsRemaining = secondsToWait - diffSeconds

    if (secondsRemaining >= 0) setSecondsRemaining(secondsRemaining)
    checkSecondsRemainingAndClearInterval(secondsRemaining)

    if (!lastDateLoaded) setLastDateLoaded(true)
  }, [getCurrentDateAsMoment, setSecondsRemaining, checkSecondsRemainingAndClearInterval, lastDateLoaded, setLastDateLoaded])

  const getCurrentCodeRequestDateAndSaveLocalStorage = useCallback((): string => {
    const currentDateAsMoment: Moment = getCurrentDateAsMoment()
    const currentDate: string = currentDateAsMoment.toISOString()

    setItem('lastCodeRequestDate', currentDate)

    return currentDate
  }, [getCurrentDateAsMoment])

  const onCompleted = useCallback((): void => {
    setICanRequestCode(false)
    setSecondsRemaining(secondsToWait)

    const lastCodeRequestDate = getCurrentCodeRequestDateAndSaveLocalStorage()

    const intervalId = setInterval(calculateSecondsRemaining, 1000, lastCodeRequestDate)

    intervalRef.current = intervalId
  }, [setICanRequestCode, setSecondsRemaining, setICanRequestCode, setSecondsRemaining, getCurrentCodeRequestDateAndSaveLocalStorage, calculateSecondsRemaining, intervalRef])

  const onError = useCallback(({ message } = {}): void => {
    const invalidToken: boolean = message === 'Invalid token'

    if (invalidToken) return console.log('Invalid Token!!')

    console.log('unknownErrorAlert')
  }, [])

  const [
    generateVerificationCode, {
      loading
    }
  ] = useLazyFetch(`${process.env.OAUTH_SERVER_HOST}/api/auth/generate-verification-code`, {
    fetchOptions: {
      method: 'POST',
      headers: isBrowser() && new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      })
    },
    onCompleted,
    onError
  })

  const requestCode = useCallback((): void => {
    if (!iCanRequestCode) return

    generateVerificationCode()
  }, [iCanRequestCode, generateVerificationCode])

  useEffect(() => {
    (async () => {
      let { lastCodeRequestDate } = getItem('lastCodeRequestDate')

      if (!Boolean(lastCodeRequestDate)) lastCodeRequestDate = getCurrentCodeRequestDateAndSaveLocalStorage()

      const intervalId = setInterval(calculateSecondsRemaining, 1000, lastCodeRequestDate)

      intervalRef.current = intervalId
    })()

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return { iCanRequestCode, secondsRemaining, requestCode, lastDateLoaded, loading }
}
