import React, { useCallback } from 'react'

import { Form } from '@wicadu/arepa/ui'
import { useLazyFetch } from '@wicadu/arepa/hooks'
import { navigate } from 'gatsby'

import { TemplateProvider } from '@hooks/useTemplate'
import CodeTemplate from '@components/templates/Code'
import useAuth, { TAuthContext } from '@hooks/useAuth'
import { AuthCode } from '@ts-types/AuthCode'
import { CheckVerificationCodeResponse } from '@ts-types/CheckVerificationCodeResponse'
import isBrowser from '@utils/isBrowser'

const { useForm } = Form

function CodeContainer() {
  const { setVerifiedAccessToken, accessToken }: TAuthContext = useAuth()
  const { handleSubmit, setError } = useForm()

  const onCompleted = useCallback(
    ({ accessToken }: CheckVerificationCodeResponse) => {
      setVerifiedAccessToken(accessToken)
      navigate('/')
    },
    [setVerifiedAccessToken]
  )

  const onError = useCallback(
    (err: Error): void => {
      if (err.message.match('Code is invalid'))
        return setError('code', {
          type: 'manual',
          message: 'Código inválido',
        })
      else if (err.message.match('Code is expired'))
        return setError('code', {
          type: 'manual',
          message: 'Código expirado',
        })

      // TODO: Throw default error
      navigate('/error')
    },
    [setError]
  )

  const [checkVerificationCode, { loading }] = useLazyFetch<CheckVerificationCodeResponse>(
    `${process.env.OAUTH_SERVER_HOST}/api/auth/verify-token`,
    {
      fetchOptions: {
        method: 'POST',
        headers: isBrowser() && new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }),
      },
      onCompleted,
      onError,
    }
  )

  const onSubmit = useCallback(
    handleSubmit(({ code }: AuthCode) => {
      const codeConvertedToNumber = Number(code)

      checkVerificationCode({
        body: {
          code: codeConvertedToNumber,
        },
      })
    }),
    [handleSubmit, checkVerificationCode]
  )

  return (
    <TemplateProvider value={{ onSubmit, loading }}>
      <CodeTemplate />
    </TemplateProvider>
  )
}

export default CodeContainer
