import React, { useMemo } from 'react'
import { Form } from '@wicadu/arepa/ui'
import { yup, yupResolver } from '@wicadu/arepa/utils'
import PropTypes, { InferProps } from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func
}

const defaultProps = {
  onSubmit () {}
}

type Props = InferProps<typeof propTypes>

const schema = yup.object().shape({
  code: (
    yup.string()
      .required('El código es requerido')
      .min(6, 'Debe ingresar 6 números')
      .max(6, 'No puede ingresar más de 6 números')
  )
})

function AuthSignupForm ({ children, onSubmit }: Props) {
  const opts = useMemo(() => ({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  }), [])

  return (
    <Form opts={opts} onSubmit={onSubmit}>{children}</Form>
  )
}

AuthSignupForm.propTypes = propTypes
AuthSignupForm.defaultProps = defaultProps

export default AuthSignupForm
