import React, { Fragment } from 'react'
import useAuth from '@hooks/useAuth'
import SEO from '@components/SEO'
import CodeContainer from '@containers/Code'
import CodeForm from '@HOCs/CodeForm'
import Redirect from '@components/encapsulators/Redirect'

function Code () {
  const { accessToken } = useAuth()

  if (!Boolean(accessToken)) return <Redirect />

  return (
    <Fragment>
      <SEO title='Verificar sesión' />

      <CodeForm>
        <CodeContainer />
      </CodeForm>
    </Fragment>
  )
}

export default Code
